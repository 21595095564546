import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables as registerablesJS,
} from 'chart.js';
import { Toaster } from 'react-hot-toast';
import Navigation from './navigation';
import 'react-calendar/dist/Calendar.css';

ChartJS.register(...registerablesJS);

ChartJS.register(ArcElement, BarController, BarElement, CategoryScale, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Filler, Legend);

function App() {
  return (
    <React.Fragment>
      <Navigation />

      <Toaster
        toastOptions={{
          style: {
            fontSize: '1.3rem',
          },
        }}
        containerStyle={{
          zIndex: '1000000',
        }}
        position='bottom-center'
        reverseOrder={false}
      />
    </React.Fragment>
  );
}

export default App;
