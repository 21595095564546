import React from 'react';

import ModalForm from '../../../components/Modal/ModalForm';
import ModalButtons from '../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../Skeletons/AddUserSkeleton';

import classes from './InsurancePolicies.module.css';

const ContactCareForm = ({ closeInsurancePoliciesModal, contactCareForm }) => {
  return (
    <React.Fragment>
      {contactCareForm.loading ? (
        <AddUserSkeleton />
      ) : contactCareForm.error ? (
        <div className={classes.form_content}>
          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>User has not filled form yet.</p>
          </div>
        </div>
      ) : (
        Object.keys(contactCareForm.data).length > 0 && (
          <div className={classes.form_content}>
            <div className={classes.form_heading}>
              <p className={classes.form_heading_content}>
                Patient's Name<span>{contactCareForm.data?.patientName}</span>
              </p>
              <p className={classes.form_heading_content}>
                Patient's BirthDate
                <span>{contactCareForm.data?.patientBirthDate}</span>
              </p>
              <p className={classes.form_heading_content}>
                Today's Date
                <span>{new Date(contactCareForm.data?.modifiedDateString).toLocaleDateString()}</span>
              </p>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>
                Who Can Contact Regarding Your Care and Billings? Contact persons with whom we can discuss your care,give test result and account and billing:
              </p>
              <div className={classes.form_relations}>
                {contactCareForm.data?.patientCareList.length > 0 &&
                  contactCareForm.data?.patientCareList.map(list => (
                    <div key={list._id} className={classes.form_relation}>
                      <p className={classes.form_relation_text}>
                        Name <span>{list.name ? list.name : '--'}</span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Relationship <span>{list.relationship ? list.relationship : '--'}</span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Phone <span>{list.phone ? list.phone : '--'}</span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>May we have confidential Information on voicemail or answering machines listed below?</p>
              <div className={classes.form_numbers}>
                <p className={classes.form_numbers_text}>
                  Home Phone: <span>{contactCareForm.data?.homePhone}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Work Phone: <span>{contactCareForm.data?.workPhone}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Cell Phone: <span>{contactCareForm.data?.cellPhone}</span>
                </p>
              </div>
            </div>
          </div>
        )
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons cancelButtonLabel='Close Insurance Policies' onCancel={closeInsurancePoliciesModal} />
      </ModalForm>
    </React.Fragment>
  );
};

export default ContactCareForm;
