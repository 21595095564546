import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { HashRouter, Routes, Route } from 'react-router-dom';

// ClinicsScreen,
import {
  LoginScreen,
  HomeScreen,
  ClinicsNewScreen,
  UsersScreen,
  ProvidersScreen,
  SecureFilesScreen,
  AdCampaignsScreen,
  AdStatisticsScreen,
  AdViewScreen,
  AppointmentsScreen,
  MyProfileScreen,
  // PatientEnrollmentScreen,
  AppointmentsNewScreen,
  // ClinicDocumentsScreen,
  FeedbackScreen,
  CheckinsScreen,
  CheckinLobbyScreen,
  ShareFileDownloadScreen,
  FeedbackResultsScreen,
} from '../pages';

// const HomePage = () => {
//   const { loading, token, userDetails } = useSelector(loginSliceValue);

//   if (loading) return <Loader />;
//   return token && userDetails.result.role === 'Super Admin' ? <OrganizationsScreen /> : <HomeScreen />;
// };

const Navigation = () => {
  const { loading, userDetails } = useSelector(loginSliceValue);

  return (
    <HashRouter>
      <Routes>
        <Route path='*' element={<LoginScreen />} />
        <Route path='/' element={<HomeScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        {/* <Route path='/enrollment' element={<PatientEnrollmentScreen />} /> */}
        <Route path='/secure-files' element={<SecureFilesScreen />} />
        <Route path='/share-file' element={<ShareFileDownloadScreen />} />
        <Route path='/my-profile' element={<MyProfileScreen />} />

        {!loading && userDetails.token && (
          <Fragment>
            {userDetails.data?.user.role === 'admin' ? (
              <Fragment>
                <Route path='/clinics' element={<ClinicsNewScreen />} />
                <Route path='/users' element={<UsersScreen />} />
                <Route path='/ad-campaigns' element={<AdCampaignsScreen />} />
                <Route path='/ad-statistics' element={<AdStatisticsScreen />} />
                <Route path='/view-campaign' element={<AdViewScreen />} />
                <Route path='/appointments' element={<AppointmentsNewScreen />} />
                <Route path='/feedback' element={<FeedbackScreen />} />
                <Route path='/patient-feedback' element={<FeedbackResultsScreen />} />
              </Fragment>
            ) : userDetails.data?.user.role === 'manager' ? (
              <Fragment>
                <Route path='/users' element={<UsersScreen />} />
                <Route path='/providers' element={<ProvidersScreen />} />
                <Route path='/appointments' element={<AppointmentsScreen />} />
                <Route path='/check-ins' element={<CheckinsScreen />} />
                <Route path='/check-in-lobby' element={<CheckinLobbyScreen />} />
                <Route path='/feedback' element={<FeedbackScreen />} />
                <Route path='/patient-feedback' element={<FeedbackResultsScreen />} />
              </Fragment>
            ) : userDetails.data?.user.role === 'receptionist' ? (
              <Fragment>
                <Route path='/users' element={<UsersScreen />} />
                <Route path='/providers' element={<ProvidersScreen />} />
                <Route path='/appointments' element={<AppointmentsScreen />} />
                <Route path='/check-ins' element={<CheckinsScreen />} />
                <Route path='/check-in-lobby' element={<CheckinLobbyScreen />} />
                {/* <Route path='/feedback' element={<FeedbackScreen />} /> */}
                <Route path='/patient-feedback' element={<FeedbackResultsScreen />} />
              </Fragment>
            ) : userDetails.data?.user.role === 'adAdmin' ? (
              <Fragment>
                <Route path='/users' element={<UsersScreen />} />
                <Route path='/ad-campaigns' element={<AdCampaignsScreen />} />
                <Route path='/ad-statistics' element={<AdStatisticsScreen />} />
                <Route path='/view-campaign' element={<AdViewScreen />} />
              </Fragment>
            ) : userDetails.data?.user.role === 'clinic' ? (
              <Fragment>
                <Route path='/users' element={<UsersScreen />} />
                <Route path='/providers' element={<ProvidersScreen />} />
                <Route path='/appointments' element={<AppointmentsScreen />} />
                <Route path='/check-ins' element={<CheckinsScreen />} />
                <Route path='/check-in-lobby' element={<CheckinLobbyScreen />} />
                <Route path='/feedback' element={<FeedbackScreen />} />
                <Route path='/patient-feedback' element={<FeedbackResultsScreen />} />
              </Fragment>
            ) : userDetails.data?.user.role === 'fileAdmin' ? (
              <Fragment>
                <Route path='/users' element={<UsersScreen />} />
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Fragment>
        )}
      </Routes>
    </HashRouter>
  );
};

export default Navigation;
